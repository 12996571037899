/*******************************
         Site Overrides
*******************************/

.ui.checkbox:not(.radio) input:checked ~ label:before {
  background: #0042b0 !important;
}

.ui.checkbox input:checked ~ label:after {
  color: #fff !important;
}

.ui.checkbox label,
.ui.checkbox + label,
.ui.checkbox label:hover,
.ui.checkbox + label:hover,
.ui.checkbox input:focus ~ label {
  color: #666 !important;
}

.ui.checkbox label {
  padding-left: 2rem;
  margin-right: 0.5rem;
  font-weight: bold;
  font-size: 0.92em;
}

.ui.toggle.checkbox {
  vertical-align: -5px;
}

.ui.small.checkbox {
  transform: scale(0.8);
  vertical-align: middle;
}

.ui.right.toggle.checkbox .box,
.ui.right.toggle.checkbox label {
  padding-left: 0 !important;
}

.ui.right.toggle.checkbox:not(.fitted) .box:before,
.ui.right.toggle.checkbox:not(.fitted) label:before {
  left: calc(100% + 1rem);
}

.ui.right.toggle.checkbox:not(.fitted) input ~ .box:after,
.ui.right.toggle.checkbox:not(.fitted) input ~ label:after {
  left: calc(100% + 0.95rem);
}

.ui.right.toggle.checkbox:not(.fitted) input:checked ~ .box:after,
.ui.right.toggle.checkbox:not(.fitted) input:checked ~ label:after {
  left: calc(100% + 3.15rem);
}

.ui.checkbox.read-only, .ui.checkbox.read-only > input, .ui.checkbox.read-only > label {
  cursor: default !important;
}
