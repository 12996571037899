/*******************************
         Site Overrides
*******************************/

.ui.primary.button {
  background-color: #0042b0;
}

.ui.button.fileButton {
  display: inline-block !important;
  background-color: #ebebeb !important;
  border: 1px dashed #b7b7b7 !important;
  font-size: 0.85rem !important;
}

.ui.button.cancel, .ui.warning.confirm.button {
  color: #DB2828 !important;
  background-color: #FFF !important;
  box-shadow: 0px 0px 0px 2px #DB2828 inset !important;
}

.ui.button.cancel:hover, .ui.warning.confirm.button:hover {
  color: #FFF !important;
  background-color: #DB2828 !important;
}

.ui.button.confirm, .ui.warning.cancel.button {
  color: #21BA45 !important;
  background-color: #FFF !important;
  box-shadow: 0px 0px 0px 2px #21BA45 inset !important;
}

.ui.button.confirm:hover, .ui.warning.cancel.button:hover {
  color: #FFF !important;
  background-color: #21BA45 !important;
}

.ui.button.textLink {
  background: none !important;
  padding: 0 !important;
  font-weight: normal;
  color: #275592;
}

.ui.button.textLink > .icon {
  margin: 0 !important;
}

.ui.white.button {
  background-color: white !important;
}

.ui.button.standard {
  background: white;
  border: 2px solid #f0f0f0;
  padding: 8px !important;
  color: @mainColor5;
}

.ui.button.standard > .icon {
  color: @mainColor5;
}

.ui.basic.button:hover {
  background-color: @mainColor1 !important;
  color: #FFF !important;
}

.ui.button.item-adder,
.ui.secondary.button,
.ui.secondary.button:hover,
.ui.secondary.button:active {
  background-color: @mainColor2 !important;
  color: white;
}

.ui.secondary.basic.button,
.ui.secondary.basic.button:hover,
.ui.secondary.basic.button:active {
  background-color: white !important;
  color: @mainColor2 !important;
  border: 1px solid @mainColor2;
  box-shadow: none !important;
}

.ui.button.template-download {
  background-color: white !important;
  border-color: @mainColor2 !important;
  color: @mainColor2 !important;
  border: solid !important;
  margin-bottom: 1em;
}

.ui.button.template-download:hover {
  background-color: @mainColor2 !important;
  color: white !important;
}

.ui.button.dashboard-opener {
  border: 2px solid @mainColor1;
  background: white;
  color: @mainColor1;
}

.ui.right.labeled.button > .ui.icon.button {
  background: @mainColor2;
  color: white;
  padding: 0.5em 0.7em !important;
}

.ui.right.labeled.button > .ui.basic.label {
  border-color: @mainColor5;
  border-left: none;
  color: @mainColor5;
  padding: 0.6em;
}

.ui.inverted.grey.button {
  color: #d4d4d5;
}

label[role="button"].ui.labeled.icon.button {
  padding-left: 4.07142857em !important;
  padding-right: 1.5em !important;
}

.ui.divided.buttons > .ui.button {
  border-right: 1px solid rgba(34, 36, 38, 0.15);
}

.ui.right.icon.button > .icon {
  margin-left: 3px !important;
}

.ui.inverted.grey.button {
  color: #999;
}

.ui.lightblue.button,
.ui.lightblue.button:focus {
  color: #fff;
  background-color: #007ac6;
}

.ui.lightblue.button:hover {
  color: #fff;
  background-color: #0090e9;
}

.ui.lightblue.button:active {
  background-color: #007ac6;
}

.confirm .actions .ui.button:last-child {
  color: #DB2828 !important;
  background-color: #FFF !important;
  box-shadow: 0px 0px 0px 2px #DB2828 inset !important;
}

.confirm .actions .ui.button:last-child:hover {
  color: #FFF !important;
  background-color: #DB2828 !important;
}

.confirm .actions .ui.button {
  color: #21BA45 !important;
  background-color: #FFF !important;
  box-shadow: 0px 0px 0px 2px #21BA45 inset !important;
}

.confirm .actions .ui.button:hover {
  color: #FFF !important;
  background-color: #21BA45 !important;
}

.ui.button.no-click {
  cursor: default;
}

.ui.form .disabled.fields .field, .ui.form .disabled.field {
  opacity: 1.0 !important;
}

.ui.form .field :disabled {
  opacity: 0.6 !important;
}
