/*******************************
        User Overrides
*******************************/

.ui.tab {
  padding: 0;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1) !important;
}


