/*******************************
         Site Overrides
*******************************/

.ui.secondary.menu {
    background-color: #000066;
    color: white;
    margin: 0;
}

.ui.secondary.menu .item {
    color: rgb(255,255,255,0.25);
    font-weight: bold;
    font-size: 12pt;
}

.ui.secondary.menu .active.item {
    color: white;
    background-color: #000044;
}

.ui.secondary.menu .item:not(.logo):hover {
    color: white !important;
    background-color: #000044 !important;
}

.ui.tabular.menu > .item {
    border-radius: 10px 0 0 0 !important;
    border: thin solid #D4D4D5;
    border-bottom: none;
    border-right: none;
    margin-right: 10px;
    background: white
}
.ui.tabular.menu > .item::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: -1px;
    right: -9px;
    bottom: 0;
    width: 12px;
    background: white;
    -webkit-transform: skew(10deg);
    transform: skew(10deg);
    border-radius: 0 9px 0 0;
    border: thin solid #D4D4D5;
    border-bottom: none;
    border-left: none;
    box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.1);
}

.ui.tabular.menu > .item:hover, .ui.tabular.menu > .item:hover::after {
    background: white;
}

.ui.tabular.menu > .active.item {
    border-radius: 10px 0 0 0 !important;
}
