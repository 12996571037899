/*******************************
         Site Overrides
*******************************/

.ui.modal > .header {
  background-color: #ebebeb;
  padding-left: 2.7rem;
  font-size: 1.3em !important;
}

.ui.modal > .header:not(:first-of-type) {
  background-color: #fff;
  border-top: 1px solid #ebebeb;
  border-bottom: none;
  margin-top: 1rem;
}

.ui.modal > .close {
  top: 1rem;
  right: 1.2rem;
  width: 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiM5OTkiIHZpZXdCb3g9IjAgMCA5NC45MjYgOTQuOTI2Ij48ZGVmcy8+PHBhdGggZD0iTTU1LjkzMSA0Ny40NjNMOTQuMzA2IDkuMDlhMi4xMTggMi4xMTggMCAwMDAtMi45OTRMODguODMzLjYyYTIuMTIzIDIuMTIzIDAgMDAtMi45OTYgMEw0Ny40NjMgMzguOTk0IDkuMDg5LjYyYy0uNzk1LS43OTUtMi4yMDItLjc5NC0yLjk5NSAwTC42MjIgNi4wOTZhMi4xMTcgMi4xMTcgMCAwMDAgMi45OTRsMzguMzc0IDM4LjM3M0wuNjIyIDg1LjgzNmEyLjExNyAyLjExNyAwIDAwMCAyLjk5NGw1LjQ3MyA1LjQ3NmEyLjEyMyAyLjEyMyAwIDAwMi45OTUgMGwzOC4zNzQtMzguMzc0IDM4LjM3NCAzOC4zNzRjLjM5Ny4zOTYuOTM3LjYyIDEuNDk4LjYyczEuMTAxLS4yMjQgMS40OTgtLjYybDUuNDczLTUuNDc2YTIuMTE4IDIuMTE4IDAgMDAwLTIuOTk0TDU1LjkzMSA0Ny40NjN6Ii8+PC9zdmc+Cg==);
}

.ui.modal > .close:before {
  content: none;
}

.ui.modal > .content {
  padding: 1.5rem 2.5rem;
}

.ui.modal > .content.noPadding {
  padding: 0;
}

.ui.modal > .actions {
  background: none;
  border-top: none;
  margin-top: 1rem;
}

.ui.modal .actionBar .fields {
  margin: 0;
}

.ui.modal.visible.active:not(.scrolling) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
