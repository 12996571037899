/*******************************
         Site Overrides
*******************************/

.ui.container.route {
    padding: 0;
    width: 100vw;
}

.ui.container.route.sub-route {
    width: calc(100vw - 250px);
}
