/*******************************
        Site Overrides
*******************************/

.noPadding .ui.message {
  margin-left: 2em;
  margin-right: 2em;
}

.ui.message {
  overflow-wrap: break-word;
}
