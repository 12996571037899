/*******************************
         Site Overrides
*******************************/

.ui.ctag.label {
  vertical-align: middle;
}

.ui.ctag.green.label {
  background-color: #05f200 !important;
}

.ui.ctag.red.label {
  background-color: #ff302f !important;
}

.ui.ctag.orange.label {
  background-color: #f6872f !important;
}

.ui.ctag.blue.label {
  background-color: #2ff6dd !important;
}

.inline.field label {
  word-break: break-all;
}
