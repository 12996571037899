/*******************************
    User Variable Overrides
*******************************/

.ui.brand.card {
  margin: 0.65em 0 0.65em 0.5em;
  width: calc(100% - 1em);
  box-shadow: 0px 0px 5px 1px #ddd;
  overflow: hidden;
  .icon.column i.icon:before {
    font-size: 1.3em;
  }
  .ui.grid > .row > .column:first-child {
    width: initial;
    margin-right: 1em;
  }
  .main.column {
    flex: 1 1 auto;
    color: #666;
    & > .header {
      font-size: 1.1em;
      color: #333;
      margin-bottom: 0.2em;
      font-weight: bold;
    }
    & > span {
      font-size: 0.9em;
      margin-right: 0.8em;
      display: inline-block;
    }
    & > .message {
      margin-top: 0.8em;
      overflow-wrap: break-word;
    }
  }
}
