/*******************************
         Site Overrides
*******************************/

.ui.table,
.ui.table thead th {
  color: #666;
}

.rt-table .rt-th:not(.centered) .rt-resizable-header-content {
  text-align: left;
  width: 100%;
}

.rt-table .rt-th:not(.centered) .rt-resizable-header-content[draggable] {
  position: relative;
  left: -9px;
}

.rt-table .rt-th.padded,
.rt-table .rt-td.padded {
  padding-left: 4em !important;
}

.rt-table .rt-td.bold {
  font-weight: bold !important;
}

.rt-table .rt-th {
  height: 27px;
}

.rt-table .rthfc-td-fixed {
  min-height: 35px;
}

.ui.table:not(.basic) .icon,
.rt-table .icon {
  color: @mainColor1;
}

.rt-table .ui.inverted.button {
  box-shadow: 0px 0px 0px 2px @mainColor1 inset !important;
}

.ui.table .ui.right.labeled.button > .ui.icon.button,
.rt-table .ui.right.labeled.button > .ui.icon.button,
.rt-table .ui.iconOnly.icon.button {
  background: @mainColor1;
}

.rt-table .ui.iconOnly.icon.button {
  margin-right: 1em;
}

.ui.table .ui.right.labeled.button:hover .icon,
.rt-table .ui.right.labeled.button:hover .icon,
.rt-table .ui.iconOnly.icon.button:hover .icon {
  opacity: 1;
}

.ui.table .ui.right.labeled.button > .ui.icon.button > .icon,
.rt-table .ui.right.labeled.button > .ui.icon.button > .icon,
.rt-table .ui.iconOnly.icon.button > .icon {
  color: white;
}

.ReactTable .-pagination .-pageJump input {
  width: 70px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 3px;
  padding: 5px 7px;
  vertical-align: middle;
}
