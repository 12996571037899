/*******************************
         Site Overrides
*******************************/

.ui.form .field > label {
  color: #666 !important;
  margin-bottom: 0.5rem;
}

.ui.form .inline.field > label {
  min-width: 120px;
}

.inline.field.textarea {
  display: flex;
  align-items: flex-start !important;
}

.inline.field.textarea > label {
  margin-top: 10px !important;
}

.inline.field.textarea > textarea {
  width: 300px;
}

.ui.form .inline.field.labelMargin > label {
  margin-left: 70px;
  min-width: 30px;
}

.ui.form .inline.field .selection.dropdown {
  vertical-align: middle;
}

.ui.form .inline.field:not(.forceOutline) input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form .rdt input,
.ui.form .inline.field:not(.forceOutline) input[type="email"],
.ui.form input[type="number"],
.ui.form .inline.field:not(.forceOutline) input[type="password"],
.ui.form .inline.field:not(.forceOutline) input[type="search"],
.ui.form .inline.field:not(.forceOutline) input[type="tel"],
.ui.form input[type="time"],
.ui.form .inline.field:not(.forceOutline) input[type="text"],
.ui.form input[type="file"],
.ui.form .inline.field:not(.forceOutline) input[type="url"] {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.ui.form .inline.field:not(.forceOutline) input:not([type]):focus,
.ui.form input[type="date"]:focus,
.ui.form input[type="datetime-local"]:focus,
.ui.form .rdt input:focus,
.ui.form .inline.field:not(.forceOutline) input[type="email"]:focus,
.ui.form .inline.field:not(.forceOutline) input[type="number"]:focus,
.ui.form .inline.field:not(.forceOutline) input[type="password"]:focus,
.ui.form input[type="search"]:focus,
.ui.form .inline.field:not(.forceOutline) input[type="tel"]:focus,
.ui.form input[type="time"]:focus,
.ui.form .inline.field:not(.forceOutline) input[type="text"]:focus,
.ui.form input[type="file"]:focus,
.ui.form .inline.field:not(.forceOutline) input[type="url"]:focus {
  border-radius: 0;
}

.ui.form .inline.field:not(.wide):not(.fillSpace) .ui.input,
.ui.form .inline.field:not(.fillSpace) .selection.dropdown,
.ui.selection {
  min-width: 300px;
  max-width: calc(100% - 135px);
}

.ui.form .autoSize .inline.field:not(.wide) .ui.input,
.ui.form .autoSize .inline.field .selection.dropdown,
.ui.selection {
  min-width: auto;
}

.ui.form .dateTime.inline.field {
  display: flex;
}

.ui.form .dateTimeWithTimeZone.fields .dateTime.inline.field .rdt {
  width: 200px;
}

.ui.form .dateTimeWithTimeZone.fields.fillSpace,
.ui.form .dateTimeWithTimeZone.fields.fillSpace .timeZone.field {
  flex-grow: 1;
}

.ui.form .field,
.ui.form .fields {
  align-items: center;
  margin-bottom: 1.3em;
}

.ui.form .fields > .field {
  margin-right: 1em;
}

.ui.form .fillSpace.field {
  display: flex;
  align-items: center;
}

.ui.form .limited.field .ui.input,
.ui.form .limited.field .selection.dropdown,
.ui.selection {
  max-width: 400px;
}

.ui.form .fillSpace.field .ui.input, .selectDepartment {
  display: inline-flex;
  flex-grow: 1;
}

.ui.form .fillSpace.field .selection.dropdown {
  width: 100%;
}

.ui.form p {
  color: #666;
}

.ui.form .required.fields:not(.grouped) > .field > label:after,
.ui.form .required.fields.grouped > label:after,
.ui.form .required.field > label:after,
.ui.form .required.fields:not(.grouped) > .field > .checkbox:after,
.ui.form .required.field > .checkbox:after {
  color: #fe0202;
  margin: -0.07em 0em 0em 0.1em;
}

.ui.form .actionBar {
  margin-top: 1.3rem !important;
  display: flex;
  align-items: center;
}

.ui.form .actionBar .buttonsLeft {
  flex: 1;
}

.ui.form .actionBar .fields {
  float: right;
  margin: 0 !important;
}

.ui.form .actionBar .field:last-of-type {
  padding-right: 0 !important;
}

.ui.form .ui.long.button {
  min-width: 100px;
}

.ui.form .ui.basic.segment {
  padding-left: 2em;
  padding-right: 2em;
}

.ui.form label.groupTitle,
.ui.form .ui.checkbox label.groupTitle:hover,
.ui.form .ui.checkbox input:focus ~ label.groupTitle {
  color: #000 !important;
  text-decoration: underline;
}

.ui.form .filterGroup.fields {
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 0 0.75rem 0.5rem;
}

.ui.form .filterGroup.fields > .field {
  margin: 0 0 1em;
  padding: 0;
  width: calc(100% * (1 / 3));
}

.ui.form .field textarea:read-only {
  color: #999;
}

.requiredStar {
  color: #fe0202;
  font-weight: bold;
}

.requiredStar:after {
  content: "*";
}
