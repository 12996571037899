/*******************************
         Site Overrides
*******************************/

.react-phone-number-input__input {
  height: auto;
}

.react-phone-number-input__country > .react-phone-number-input__icon {
  position: relative;
}

.react-phone-number-input__country
  > .react-phone-number-input__icon
  > .react-phone-number-input__icon-image {
  position: absolute;
  top: 0;
  left: 0;
}
