/*******************************
         Site Overrides
*******************************/

.calendar.outline.icon {
  color: #ccc;
}

.aside .list .icon {
  top: 0.5em;
  position: relative;
}

i.icons .icon:first-child {
  margin-right: 0;
}

.primary.icon {
  color: @mainColor1;
}

.ui.breadcrumb > .section > .ui.header > .icon,
.list-grid .ui.header > .icon,
.table-top-buttons .ui.header > .icon,
.sidebar .header > .icon,
.brand.icon {
  position: relative;
  width: 1.8em;
  height: 1.8em;
  border-radius: 6px;
  background: #f6892d;
  padding: 3px;
  color: white;
  font-size: @pleteoHeaderFontSize;
  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.centered.icon {
  display: block;
  margin: 0 auto;
}

.warning.icon {
  color: #FBBD08 !important;
}