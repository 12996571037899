/*******************************
         Site Overrides
*******************************/

.ui.breadcrumb > .section {
    margin-bottom: .5em;
}

.ui.breadcrumb > .section > .ui.header {
    margin: 1em 1em;
    font-size: @pleteoHeaderFontSize;
    font-weight: bold;
}

.ui.breadcrumb .active.section {
    color: @mainColor1;
}

.ui.breadcrumb a {
    color: @mainColor4;
}

.ui.breadcrumb a:hover {
    color: @mainColor5;
}