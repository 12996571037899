/*******************************
         Site Overrides
*******************************/

.ui.form .ui.header {
  color: #555;
}

.ui.form .ui.header:not(.icon) {
  margin: 2.7rem 0 1rem;
  padding-left: 2rem;
}

.ui.form .ui.dividing.header {
  padding-bottom: 1.3rem;
}

.list-grid .ui.header,
.table-top-buttons .ui.header {
    margin: 1em 1em;
    font-size: @pleteoHeaderFontSize;
    font-weight: bold;
}

.ui.modal .ui.dividing.header {
  font-size: 1.15em;
  padding-left: 2.7rem;
  padding-bottom: 1rem;
  margin-top: 1.2rem;
  color: #000;
}