/*******************************
        User Overrides
*******************************/

.ui.selection.dropdown:not(.bordered) {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.ui.portal.dropdown > .icon {
  display: none;
}

.ui.dropdown .menu > .item.hideText > .text {
  display: none;
}

.ui.dropdown .menu > .item {
  max-height: 10em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  min-inline-size: max-content;
}

.ui.upward.custom.dropdown > .menu {
  top: 100%;
  bottom: auto;
}

.ui.dropdown {
  user-select: none;
}

.ui.dropdown .scrolling.menu,
.ui.scrolling.dropdown .menu {
  bottom: auto !important;
}

.ui.dropdown.button {
  .dropdown.icon {
    padding-left: 0.5em;
  }
}

.ui.dropdown.cf-dropdown > .menu {
  max-width: 60vw;
}

.ui.small.modal .ui.dropdown.cf-dropdown > .menu {
  max-width: 40vw;
}
