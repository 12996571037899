/*******************************
         Site Overrides
*******************************/

.new-notif-indicator {
    height: 5px;
    width: 5px;
    background-color: #f6882e;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 0.20em;
    right: 0.10em;
  }

.new-notif-indicator-contact {
    height: 10px;
    width: 10px;
    background-color: #f6882e;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    margin-top: -0.4em;
    margin-left: -0.5em;
  }

