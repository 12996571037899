/*******************************
         Site Overrides
*******************************/

.ui.grid {
  margin: 0;
}

.ui.grid.noPadding > .row {
  padding: 0 !important;
}

.ui.list-grid {
    margin: 1.5em;

    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    background: white;

    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    border: thin solid #E0E0E0;
}

.ui.grid > .row {
    padding: 0;
}

.ui.grid > .row > .column {
    padding: 0;
}

.aside {
  overflow-wrap: anywhere !important;
}

.ui.grid > .row > .column.aside {
  background: white;
  height: 100%;
}

.ui.grid > .row > .column.aside > * {
  display: block;
  margin: 1.5em;
}

.ui.grid > .row > .column.aside .column > * {
  margin: .5em;
}

.ui.grid .ui.basic.segment {
  padding-left: 2em;
  padding-right: 2em;
}